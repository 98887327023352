











































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question502',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {text: 'The structure has one positive charge but no negative charges', value: '1pos0neg'},
        {text: 'The structure has one negative charge but no positive charges', value: '0pos1neg'},
        {text: 'The structure has one positive charge and one negative charge', value: '1pos1neg'},
        {text: 'The structure does not contain any charges', value: 'noCharges'},
      ],
      optionsFr: [
        {
          text: 'La structure contient une charge positive, mais aucune charge négative',
          value: '1pos0neg',
        },
        {
          text: 'La structure contient une charge négative, mais aucune charge positive',
          value: '0pos1neg',
        },
        {
          text: 'La structure contient une charge positive et une charge négative',
          value: '1pos1neg',
        },
        {text: 'La structure est de charge neutre', value: 'noCharges'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/determiningMissingCharges1.png';
    },
  },
};
